import { FC, PropsWithChildren, createContext, useRef } from 'react';

import { AccommodationStore, createAccommodationStore } from './accommodationStore';

export const ZustandAccommodationContext = createContext<AccommodationStore | null>(null);

export const ZustandAccommodationProvider: FC<PropsWithChildren> = ({ children }) => {
	const store = useRef<AccommodationStore>();

	if (!store.current) {
		/** Only wrap whe component is not yet wrapped with the store */
		store.current = createAccommodationStore();
	}

	return (
		<ZustandAccommodationContext.Provider value={store.current}>{children}</ZustandAccommodationContext.Provider>
	);
};
