import { addBrandPrefix } from '@vakantiesnl/services/src/stores/brandPrefix';
import { Search } from '@vakantiesnl/types/src';
import { StoreApi, createStore } from 'zustand';
import { devtools } from 'zustand/middleware';

export type AccommodationState = {
	loading: boolean;
	/** Final selected offer */
	currentOffer: null | Search.OfferItem | Search.AccommodationOnlyOffer;
	/** Cheapest offer of the selected price bar */
	currentCheapestOffer: null | Search.OfferItem | Search.AccommodationOnlyOffer;
};

type AccommodationActions = {
	setCurrentOffer: (data: Search.OfferItem | Search.AccommodationOnlyOffer | null) => void;
	setCurrentCheapestOffer: (data: Search.OfferItem | Search.AccommodationOnlyOffer | null) => void;
	clearOffers: () => void;
	setIsOfferLoading: (loading: boolean) => void;
};

export type AccommodationStateWithActions = AccommodationState & AccommodationActions;
export type AccommodationStore = StoreApi<AccommodationStateWithActions>;

const initialAccommodationState: AccommodationState = {
	loading: Boolean(true),
	currentOffer: null,
	currentCheapestOffer: null,
};

export function createAccommodationStore(): AccommodationStore {
	return createStore<AccommodationStateWithActions>()(
		devtools(
			(set) => ({
				...initialAccommodationState,

				setCurrentOffer(currentOffer): void {
					set(() => ({ currentOffer, loading: false }), undefined, 'setCurrentOffer');
				},
				setCurrentCheapestOffer(currentCheapestOffer): void {
					set(() => ({ currentCheapestOffer, loading: false }), undefined, 'setCurrentCheapestOffer');
				},

				setIsOfferLoading(loading: boolean): void {
					set(() => ({ loading }), undefined, 'setIsOfferLoading');
				},

				clearOffers(): void {
					set(
						() => ({
							currentOffer: null,
							loading: true,
						}),
						undefined,
						'clearOffers',
					);
				},
			}),
			{ name: addBrandPrefix('AccommodationStore') },
		),
	);
}
