import { ParsedUrlQuery } from 'querystring';

import { initialFilterState } from './config';
import {
	getThemes,
	getPage,
	getMinPrice,
	getMaxPrice,
	getAccoTypes,
	getMealplans,
	getDepartureDate,
	getFlexibleDepartureDate,
	getDomain,
	getSorting,
	getAwards,
	getDistances,
	getFacilities,
	getRating,
	getDeals,
	getCampaignAccoTypes,
	getCampaignDepartureDate,
	getAirport,
	getTransport,
	getChainsParamsFromQuery,
	getTourOperatorsParamsFromQuery,
	getStars,
	getCampaignAttributes,
	getCampaignMaxPrice,
	getCampaignMinPrice,
} from './getFilterStateHelpers';
import { FilterState } from './interfaces';
import {
	accoTypesToSetOwnTransportFor,
	campaignsToSetOwnTransportFor,
	countriesToSetOwnTransportFor,
	domainsToSetFlightTransportFor,
	domainsToSetOwnTransportFor,
} from '../../constants/setOwnTransport';
import { SelectedGeo } from '../../hooks/queries';
import { getLastMinuteDateRange } from '../../util/dateUtils';
import { getCampaignFromQuery, parseQueryParam } from '../../util/queryHelpers';

export function getFilterState(query: ParsedUrlQuery, selectedGeo: SelectedGeo): FilterState {
	const filterState: FilterState = { ...initialFilterState };

	const { countries, regions, cities } = selectedGeo;

	if (!!countries.length) {
		filterState.countrySlugs = countries.map((c) => c.slug);
		filterState.countries = countries.map((c) => ({ entityId: c.entityId, slug: c.slug }));
	}

	const shouldAllowRegionFilter = filterState.countries?.length;
	if (shouldAllowRegionFilter) {
		filterState.regionSlugs = regions.map((r) => r.slug);
		filterState.regions = regions.map((r) => ({ entityId: r.entityId, slug: r.slug }));
	}

	const shouldAllowCityFilter = filterState.regions?.length > 0 && filterState.countries.length === 1;
	if (shouldAllowCityFilter) {
		filterState.citySlugs = cities.map((c) => c.slug);
		filterState.cities = cities.map((city) => ({ entityId: city.entityId, slug: city.slug }));
	}

	const themes = getThemes(query);
	if (themes) {
		filterState.themes = themes;
	}

	const page = getPage(query);
	if (page) {
		filterState.page = page;
	}

	const minPrice = getMinPrice(query);
	if (minPrice) {
		filterState.priceMin = minPrice;
	}

	const maxPrice = getMaxPrice(query);
	if (maxPrice) {
		filterState.priceMax = maxPrice;
	}

	const accoTypes = getAccoTypes(query);
	if (accoTypes) {
		filterState.accoType = accoTypes;
	}

	const mealplans = getMealplans(query);
	if (mealplans) {
		filterState.mealplans = mealplans;
	}

	const airports = getAirport(query);
	if (airports) {
		filterState.departureAirport = airports;
	}

	const departureDate = getDepartureDate(query);
	if (departureDate) {
		filterState.departureDate = departureDate;
	}

	const flexibleDepartureDate = getFlexibleDepartureDate(query);
	if (flexibleDepartureDate && departureDate && departureDate.length === 1) {
		filterState.flexibleDepartureDate = flexibleDepartureDate;
	}

	const domain = getDomain(query);
	if (domain) {
		filterState.domain = domain;
	}

	// Override for Vakanties as it still has the lastminute domain
	if (process.env.NEXT_PUBLIC_BRAND !== 'ZVRNL' && domain === 'lastminute' && !departureDate?.length) {
		filterState.departureDate = getLastMinuteDateRange();
	}

	const awards = getAwards(query);
	if (awards) {
		filterState.awards = awards;
	}

	const distances = getDistances(query);
	if (distances) {
		filterState.distances = distances;
	}

	const facilities = getFacilities(query);
	if (facilities) {
		filterState.facilities = facilities;
	}

	const rating = getRating(query);
	filterState.rating = rating || '';

	const stars = getStars(query);
	if (stars) {
		filterState.stars = stars;
	}

	const isDealsRoute = getDeals(query);
	filterState.isDealsRoute = isDealsRoute || false;

	const transport = getTransport(query);
	if (transport) {
		filterState.transport = transport;
	}

	const sort = getSorting(query);
	if (sort) {
		filterState.sort = sort;
	}

	const campaign = getCampaignFromQuery(query);

	// Anything in the query takes priority over the campaign config (same as campaign geo, which is handled in another fn: getGeoParamsFromQuery)
	if (campaign) {
		filterState.campaign = campaign.slug;

		// Only set accoTypes from campaign when they were not already part of the query
		if (!filterState.accoType.length) {
			const campaignAccoTypes = getCampaignAccoTypes(campaign);

			if (campaignAccoTypes) {
				filterState.accoType = campaignAccoTypes;
			}
		}

		// Only set departure from campaign when they were not already part of the query
		const campaignDepartureDate = getCampaignDepartureDate(campaign);

		if (!filterState.departureDate?.length && campaignDepartureDate?.length) {
			filterState.departureDate = campaignDepartureDate;
		}

		const mealplans = parseQueryParam(campaign.mealplans);

		if (mealplans && !filterState.mealplans?.length) {
			filterState.mealplans = mealplans;
		}

		const campaignAttributes = getCampaignAttributes(campaign);

		if (!filterState.attributes.length && campaignAttributes?.length) {
			filterState.attributes = campaignAttributes;
		}

		const campaignMinPrice = getCampaignMinPrice(campaign);

		if (campaignMinPrice && !filterState.priceMin) {
			filterState.priceMin = campaignMinPrice;
		}

		const campaignMaxPrice = getCampaignMaxPrice(campaign);

		if (campaignMaxPrice && !filterState.priceMax) {
			filterState.priceMax = campaignMaxPrice;
		}
	}

	// Set the default transport to own transport for a sub set of countries, accoTypes and campaigns as requested by commerce. Please note, this is also done when refreshing the page, it's a known side-effect but we cannot prevent it
	const selectedAccoTypes = filterState.accoType;

	if (
		!(domain && domainsToSetFlightTransportFor.includes(domain) && !query.transport) && // This overrides cases where we have italie/stedentrip for example. We want to force that to including flights
		((countries.length === 1 && countriesToSetOwnTransportFor.includes(countries[0].id)) ||
			(selectedAccoTypes?.length === 1 && accoTypesToSetOwnTransportFor.includes(selectedAccoTypes[0])) ||
			(campaign && campaignsToSetOwnTransportFor.includes(campaign.slug) && !query.transport) ||
			(domain && domainsToSetOwnTransportFor.includes(domain) && !query.transport))
	) {
		filterState.transport = 'OWN';
	}

	const chains = getChainsParamsFromQuery(query);
	if (!!chains) {
		filterState.chains = chains;
		filterState.chainsSlugs = chains.map((chain) => chain.slug);
	}

	const tourOperators = getTourOperatorsParamsFromQuery(query);
	if (!!tourOperators) {
		filterState.tourOperators = tourOperators;
		filterState.tourOperatorsSlugs = tourOperators.map((tourOperator) => tourOperator.slug);
	}

	return filterState;
}
