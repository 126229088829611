import { useContext } from 'react';

import { useStoreWithEqualityFn } from 'zustand/traditional';

import { ZustandAccommodationContext } from './accommodationProvider';
import { AccommodationStateWithActions } from './accommodationStore';

export function useZustandAccommodationContext<T>(
	selector: (state: AccommodationStateWithActions) => T,
	equalityFn?: (left: T, right: T) => boolean,
): T {
	const store = useContext(ZustandAccommodationContext);

	if (!store) throw new Error('Missing ZustandAccommodationContext.Provider in the tree');

	return useStoreWithEqualityFn(store, selector, equalityFn);
}
